<template>
  <div class="salaryInfo-container">
    <list
      ref="unOwnerMgrList"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      exportPermission="export"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-select2 label="所属公司" v-model="searchParams.tenantId" v-bind="regionParams"></v-select2>
        <v-input v-model="searchParams.userName" label="姓名"></v-input>
        <div class="department">
          <span>部门</span>
        <select-tree
                :value.sync="searchParams.orgId"
                :data.sync="treeSelectData"
                placeholder="查询所属部门"
                :treeData="treeData"
                :treeProps="treeProps"
                :highlightCurrent="true"
                :filterNodeMethod="filterNodeMethod"
                :expand-on-click-node="false"
              /></div>
        <v-input v-model="searchParams.mobileNum" label="联系电话"></v-input>
        <v-input v-model="searchParams.cardNum" label="证件号码"></v-input>
        <v-input v-model="searchParams.jobNum" label="员工编号"></v-input>
        <v-datepicker
          label="入职时间"
          :startTime.sync="searchParams.jobEnterDateS"
          :endTime.sync="searchParams.jobEnterDateE"
        ></v-datepicker>
        <div class="tripMode">
          <span>当前状态</span
          ><checkbox-plus
            mode="checkbox-plus"
            type="default"
            :options="userTypeOps"
            :value.sync="userType"
            @onChange="userTypeChange"
          />
        </div>
        <v-datepicker
          label="合同开始时间"
          :startTime.sync="searchParams.contractStartDateS"
          :endTime.sync="searchParams.contractStartDateE"
        ></v-datepicker>
        <v-datepicker
          label="合同结束时间"
          :startTime.sync="searchParams.contractEndDateS"
          :endTime.sync="searchParams.contractEndDateE"
        ></v-datepicker>
        <v-input v-model="searchParams.adminDuty" label="岗位"></v-input>
        <v-select
          v-model="searchParams.jobRank"
          label="职级"
          :options="gradeOps"
        ></v-select>
        <v-select
          v-model="searchParams.salaryGrade"
          label="薪资档级"
          :options="salaryGradeOps"
        ></v-select>
        <v-select
          v-model="searchParams.contractExpired"
          label="合同是否到期"
          :options="statusOps"
        ></v-select>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          permission="update"
          @click="edit(scope.row)"
        ></v-button>
        <v-button
          text="查看"
          type="text"
          permission="detail"
          @click="look(scope.row)"
        ></v-button>
        <v-button
          text="更多"
          type="text"
          permission="more"
          @click="more(scope.row)"
        ></v-button>
      </template>
    </list>
  </div>
</template>
<script>
import { getListURL, exportUrl, getInitInfoURL ,getEnterOrgTreeListURL} from "./api";
// import { isPaymentMap,userTypeMap } from "./map";
import { mapHelper } from "common/utils";
import { CheckboxPlus,SelectTree } from "components/bussiness";
import { regionParams } from 'common/select2Params'
export default {
  name: "salaryStandardList",
  components: { CheckboxPlus,SelectTree },
  data() {
    return {
      orgType: 2,
      regionParams,
      searchUrl: getListURL,
      exportUrl: exportUrl,
      searchParams: {
        tenantId: '',
        userName: "",
        orgId: "",
        mobileNum: "",
        cardNum: "",
        jobNum: "",
        staffStatusList: undefined,
        adminDuty:"",
        jobRank: undefined,
        salaryGrade: undefined,
        contractExpired: undefined,
        contractStartDateS:"",
        contractStartDateE:"",
        contractEndDateS:"",
        contractEndDateE:"",
      },
      postOps: [],
      gradeOps: [],
      salaryGradeOps: [],
      departmentOps: [],
      statusOps: [],
      userType: [],
      userTypeOps: [],
      headers: [
        {
          prop: "tenantName",
          label: "公司名称",
        },
        {
          prop: "jobNum",
          label: "员工编号",
        },
        {
          prop: "userName",
          label: "姓名",
        },
        {
          prop: "cardType",
          label: "证件类型",
        },
        {
          prop: "cardNum",
          label: "证件号码",
        },
        {
          prop: "mobileNum",
          label: "联系电话",
        },
        {
          prop: "orgName",
          label: "部门",
        },
        {
          prop: "adminDuty",
          label: "岗位",
        },
        {
          prop: "jobRank",
          label: "职级",
        },
        {
          prop: "salaryGrade",
          label: "薪资档级",
        },
        {
          prop: "jobEnterDate",
          label: "入职时间",
        },
        {
          prop: "contractStartDate",
          label: "合同开始时间",
        },
        {
          prop: "contractEndDate",
          label: "合同结束时间",
        },
        {
          prop: "staffStatus",
          label: "当前状态"         
        },
        {
          prop: "contractExpired",
          label: "合同是否到期"
        },
        {
          prop: "updateUserName",
          label: "操作人",
        },
      ],
      /* select-tree 控件 */
      treeSelectData: {}, // 所选树节点的数据
      treeProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'childOrgList', // 必须
        label: 'orgName', // 必须
        // isLeaf: (data, node) => {    // 必须
        //   return data.hasChild === 0
        // },
        btn: 'operateArr' // 必须
      },
      treeData: [],
    };
  },
  created() {
    this.getInitInfo();
    this.getTreeList()
  },
  methods: {
    getTreeList () { // 所属部门树数据
      this.$axios.get(`${getEnterOrgTreeListURL}?orgType=${this.orgType}`)
        .then((res) => {
          if (res.status === 100 && res.msg === 'ok') {
            this.treeData = res.data
            this.keyTree += 1
          }
        })
    },
    /* 选择树控件 */
    filterNodeMethod (value, data) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true
      return data[this.treeProps.label].indexOf(value) !== -1
    },
    userTypeChange(index, val) {
      this.searchParams.staffStatusList = val.toString();
    },
    edit(row) {
      this.$router.push({
        name: "staffManageForm",
        query: {
          id: row.userId,
          newId: row.enterpriseUserId,
          // orgId: row.orgId,
        },
      });
    },
    look(row) {
      this.$router.push({
        name: "staffManageForm",
        query: {
          id: row.userId,
          newId: row.enterpriseUserId,
          isLook: 1
        },
      });
    },
    more(row) {
      this.$router.push({
        name: "staffMoreList",
        query: {
          id: row.userId,
          enterpriseUserId: row.enterpriseUserId
        },
      });
    },
    create() {
      this.$router.push({
        name: "staffManageForm",
      });
    },
    grant(row) {
      this.$router.push({
        name: "staffManageForm",
        query: {
          id: row.id,
        },
      });
    },
    getInitInfo() {
      this.$axios.get(getInitInfoURL).then((res) => {
        if (res.status === 100) {
          // this.postOps = this.dealData(res.data.countryList); //部门
          this.gradeOps = this.dealData(res.data.jobRankList);
          this.salaryGradeOps = this.dealData(res.data.salaryGradeList);
          this.statusOps = this.dealData(res.data.yesOrNoList)
          this.userTypeOps = this.dealData1(res.data.staffStatusList)
        }
      });
    },
    dealData(arr) {
      // 处理数据使符合下拉框格式
      let list = [];
      arr.forEach((item) => {
        list.push({
          text: item,
          value: item,
        });
      });
      const { setOps } = mapHelper.setMap(list);
      return list.length > 0 ? setOps(1) : list;
    },
    dealData1(arr) {
      // 处理数据使符合下拉框格式
      let list = [];
      arr.forEach((item) => {
        list.push({
          label: item,
          value: item,
        });
      });
      return list
    },
  },
};
</script>
<style lang="scss">
.salaryInfo-container {
  .tripMode {
    padding-left: 50px;
    display: flex;
    > span {
      display: inline-block;
      margin: 15px 10px 0 0;
    }
  }
  .department {
    padding-left: 50px;
    display: flex;
    > span {
      display: inline-block;
      margin: 10px 5px 0 0;
    }
  }
}
</style>

// 列表
const getListURL = `${API_CONFIG.butlerBaseURL}staffManagement/getStaffList`
// 导出
const exportUrl = `${API_CONFIG.butlerBaseURL}staffManagement/exportStaffList`
// // 新增
// const saveOutURL = `${API_CONFIG.shopBaseURL}staffBasicInfo/add`
// // 查询
// const queryURL = `${API_CONFIG.shopBaseURL}enterpriseUserInfo/getEnterpriseUserInfoDetail`
// // 编辑
// const updateOutURL = `${API_CONFIG.shopBaseURL}staffBasicInfo/update`
// 开启/关闭
const closeURL = ''
// 下拉选项数据
const getInitInfoURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/getEnterpriseUserInitCodeWordList`
// // 下拉选项数据
// const getEnterpriseUserInitCodeWordListURL = `${API_CONFIG.butlerBaseURL}talentUserInfo/getInitCodeWordList`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=shopgoods`
// // 查询详情
// const queryURL1 = `${API_CONFIG.baseURL}serverVoteAction!queryOne.action`
// 获取组织树
const getEnterOrgTreeListURL = `${API_CONFIG.butlerBaseURL}enterpriseOrgInfo/getEnterOrgTreeList`
// 查询薪资标准
const getSalaryStandardByTenantAndGradeURL = `${API_CONFIG.butlerBaseURL}salaryStandard/getSalaryStandardByTenantAndGrade`

// 新增企业组织人员信息
const addEnterpriseUserInfoURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/addEnterpriseUserInfo`
// 编辑企业组织人员信息
const updateEnterpriseUserInfoURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/updateEnterpriseUserInfo`
// 人员详情列表
const getEnterpriseUserInfoDetailURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/getEnterpriseUserInfoDetail`
// 查询
const queryURL = `${API_CONFIG.shopBaseURL}talentUserInfo/getTalentUserDetail`
/* 老接口 */
// 角色列表
const getOrganizeURL = `${API_CONFIG.baseURL}serverRoleAction!getOrganizeAccount.action`
// 角色归属列表
const getRolesURL = `${API_CONFIG.butlerBaseURL}sysRole/select2Roles`
// 获取角色类型
const getRoleTypeURL = `${API_CONFIG.baseURL}serverUserAction!getRoleType.action`
// 新增
const addManageUserURL = `${API_CONFIG.butlerBaseURL}manager/register`
// 更新
const updateManageUserURL = `${API_CONFIG.butlerBaseURL}manager/update`
// 获取详情
const getUserInfoURL = `${API_CONFIG.baseURL}serverUserAction!view.action`
// 重置密码
const resetPwgURL = `${API_CONFIG.butlerBaseURL}manager/resetPwd`
// 获取授权业务服务列表
const getViewOauthURL = `${API_CONFIG.baseURL}serverUserAction!viewOauth.action`
// 获取授权门店列表
const getStoreListURL = `${API_CONFIG.butlerBaseURL}user/auth/store`
// 获取组织列表
const getOrgRelationListURL = `${API_CONFIG.butlerBaseURL}sysRole/orgRelation`
// 获取组织标签列表
const getDropDownSearchTagURL = `${API_CONFIG.butlerBaseURL}orgTag/getDropDownSearchTag`
// 账号解锁
const unlockedURL = `${API_CONFIG.baseURL}serverUserAction!unLock.action`
// 物管端App开启待办项配置
const updateTypeURL = `${API_CONFIG.butlerBaseURL}manager/user/notice/updateType`
// 批量关闭或开启用户
const batchURL = `${API_CONFIG.butlerBaseURL}manager/change/status`
export {
  getListURL,
  exportUrl,
  closeURL,
  getInitInfoURL, 
  uploadURL,
  getEnterOrgTreeListURL,
  getSalaryStandardByTenantAndGradeURL,
  getRoleTypeURL,
  getOrganizeURL,
  getRolesURL,
  getOrgRelationListURL,
  getDropDownSearchTagURL,

  addManageUserURL,
  updateManageUserURL,
  getUserInfoURL,
  resetPwgURL,
  getViewOauthURL,
  getStoreListURL,
  unlockedURL,
  updateTypeURL,
  addEnterpriseUserInfoURL,
  updateEnterpriseUserInfoURL,
  getEnterpriseUserInfoDetailURL,
  queryURL,
  batchURL
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "salaryInfo-container" },
    [
      _c("list", {
        ref: "unOwnerMgrList",
        attrs: {
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          exportPermission: "export",
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属公司" },
                      model: {
                        value: _vm.searchParams.tenantId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "tenantId", $$v)
                        },
                        expression: "searchParams.tenantId",
                      },
                    },
                    "v-select2",
                    _vm.regionParams,
                    false
                  )
                ),
                _c("v-input", {
                  attrs: { label: "姓名" },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "department" },
                  [
                    _c("span", [_vm._v("部门")]),
                    _c("select-tree", {
                      attrs: {
                        value: _vm.searchParams.orgId,
                        data: _vm.treeSelectData,
                        placeholder: "查询所属部门",
                        treeData: _vm.treeData,
                        treeProps: _vm.treeProps,
                        highlightCurrent: true,
                        filterNodeMethod: _vm.filterNodeMethod,
                        "expand-on-click-node": false,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.searchParams, "orgId", $event)
                        },
                        "update:data": function ($event) {
                          _vm.treeSelectData = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c("v-input", {
                  attrs: { label: "联系电话" },
                  model: {
                    value: _vm.searchParams.mobileNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "mobileNum", $$v)
                    },
                    expression: "searchParams.mobileNum",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "证件号码" },
                  model: {
                    value: _vm.searchParams.cardNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "cardNum", $$v)
                    },
                    expression: "searchParams.cardNum",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "员工编号" },
                  model: {
                    value: _vm.searchParams.jobNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "jobNum", $$v)
                    },
                    expression: "searchParams.jobNum",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "入职时间",
                    startTime: _vm.searchParams.jobEnterDateS,
                    endTime: _vm.searchParams.jobEnterDateE,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "jobEnterDateS", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "jobEnterDateS", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "jobEnterDateE", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "jobEnterDateE", $event)
                    },
                  },
                }),
                _c(
                  "div",
                  { staticClass: "tripMode" },
                  [
                    _c("span", [_vm._v("当前状态")]),
                    _c("checkbox-plus", {
                      attrs: {
                        mode: "checkbox-plus",
                        type: "default",
                        options: _vm.userTypeOps,
                        value: _vm.userType,
                      },
                      on: {
                        "update:value": function ($event) {
                          _vm.userType = $event
                        },
                        onChange: _vm.userTypeChange,
                      },
                    }),
                  ],
                  1
                ),
                _c("v-datepicker", {
                  attrs: {
                    label: "合同开始时间",
                    startTime: _vm.searchParams.contractStartDateS,
                    endTime: _vm.searchParams.contractStartDateE,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "contractStartDateS",
                        $event
                      )
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "contractStartDateS",
                        $event
                      )
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "contractStartDateE",
                        $event
                      )
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "contractStartDateE",
                        $event
                      )
                    },
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "合同结束时间",
                    startTime: _vm.searchParams.contractEndDateS,
                    endTime: _vm.searchParams.contractEndDateE,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "contractEndDateS",
                        $event
                      )
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "contractEndDateS",
                        $event
                      )
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "contractEndDateE",
                        $event
                      )
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "contractEndDateE",
                        $event
                      )
                    },
                  },
                }),
                _c("v-input", {
                  attrs: { label: "岗位" },
                  model: {
                    value: _vm.searchParams.adminDuty,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "adminDuty", $$v)
                    },
                    expression: "searchParams.adminDuty",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "职级", options: _vm.gradeOps },
                  model: {
                    value: _vm.searchParams.jobRank,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "jobRank", $$v)
                    },
                    expression: "searchParams.jobRank",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "薪资档级", options: _vm.salaryGradeOps },
                  model: {
                    value: _vm.searchParams.salaryGrade,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "salaryGrade", $$v)
                    },
                    expression: "searchParams.salaryGrade",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "合同是否到期", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.contractExpired,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "contractExpired", $$v)
                    },
                    expression: "searchParams.contractExpired",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "查看", type: "text", permission: "detail" },
                  on: {
                    click: function ($event) {
                      return _vm.look(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "更多", type: "text", permission: "more" },
                  on: {
                    click: function ($event) {
                      return _vm.more(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }